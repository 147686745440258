.footer-wrapper {
    width: 1392px;
    max-width: 100%;
    margin: 0 auto;
    padding: 24px 24px 48px 24px;
    display: flex;
    flex-direction: column;
}
    .footer-top-row-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 12px 0;
    }

        .footer-icons-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
        }
            .footer-icons-icon {
                width: 40px;
                height: 40px;
                cursor: pointer;
            }

    .footer-phone {
        margin: 0 0 8px 0;
        color: #FFF;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
    }

    .footer-email {
        margin: 0 0 24px 0;
        color: #FFF;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
    }

    .footer-bottom-row-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #FFF;
    }

        .footer-address {
            color: #FFF;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
        }
        .footer-link {
            max-width: 270px;
            text-align: right;
            color: #fff;
        }

@media (max-width: 750px) {
    .footer-wrapper {
        position: relative;
        z-index: 5;
        margin-top: 180px;
        padding: 0 40px 32px 40px;
        background-color: #333;
        text-align: left;
    }
        
    .footer-top-row-wrapper {
        flex-direction: column;
        align-items: flex-start;
    }
        .footer-logo {
            margin: 0 0 20px 0;
        }
        .footer-icons-wrapper {
            margin: 0 0 36px 0;
        }
    .footer-email {
        margin: 0 0 48px 0;
    }
    .footer-bottom-row-wrapper {
        flex-direction: column;
        align-items: flex-start;
    }
        .footer-address {
            order: 1;
            margin: 0 0 24px 0;
        }
        .footer-link {
            order: 2;
            margin: 0 0 32px 0;
            text-align: left;
        }
        .footer-create {
            order: 3;
        }
}