.login-box-wrapper {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: fit-content;
    padding: 20px;
    border-radius: 15px;
}

.login-title {
    font-size: 26px;
    font-family: Mulish;
}

.login-input {
    font-family: Mulish;
}

.login-button {
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    padding: 16px 32px;
    cursor: pointer;
    /* width: 100%; */
    border-radius: 8px;
    font-family: Mulish;
    background-color: #333;
    color: #fff;
    margin-top: 20px;
}

.login-input {
    display: block;
    /* width: 100%; */
    padding: 16px 32px 16px 16px;
    background-color: rgba(44, 44, 44, 0.1);
    border: 1px rgba(0, 0, 0, 0.2) solid;
    border-radius: 8px;
    margin-top: 10px;
    min-width: 350px;
    font-size: 1em;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }

  .login-input::placeholder {
    font-family: Mulish;
    font-size: 0.875em;
    color: #a7a7a7;
  }
