.team-personal {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 132px;
	position: relative;
	min-width: 625px;
	width: 100%;
	margin: 0 0 32px 0;
	padding: 24px;
	border-radius: 24px;
	background-color: rgba(255, 255, 255, 0.04);
}
	.team-personal h1 {
		font-size: 24px;
		font-weight: 900;
		line-height: 1.2;
	}
	.team-param {
		display: flex;
		align-items: center;
		gap: 75px;
		padding: 0 32px 0 0;
	}
		.team-param__item {
			display: flex;
			align-items: center;
			gap: 24px;
		}
			.team-param__item-title {
				font-size: 1.5em;
				font-weight: 900;
				line-height: 1.2;
			}
			.team-param__item-body {
				font-size: 1.5em;
				line-height: 1.2;
			}

.grade-wrapper {
	width: 100%;
	max-width: 40.75rem;
	margin: 0 auto 96px auto;
}

	.grade-wrapper h2 {
		text-align: center;
	}
	.grade-wrapper__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 0 8px 0;
		padding: 16px 35px;
		font-size: 20px;
		font-style: normal;
		line-height: 1.2;
		border-radius: 24px;
		background-color: rgba(255, 255, 255, 0.04);
	}
		.grade-wrapper__name {
			width: calc(100% - 210px);
			font-weight: 600;
		}
		.grade-wrapper__status {
			width: 210px;
			font-weight: 800;
		}
		.table-header-cell-wrapper .table-item__desc-hide span {
			display: none;
		}
@media (max-width: 1200px) {
	.team-param {
		gap: 40px;
		font-size: 14px;
	}
}

@media (max-width: 1000px) {
	.team-personal {
		align-items: start;
		gap: 64px;
	}
	.team-param {
		flex-wrap: wrap;
		align-items: flex-start;
		gap: 24px;
	}
		.team-param__item {
			width: calc(50% - 12px);
		}
		.team-param__start {
			width: 100%;
		}
			.team-param__start span {
				width: 50%;
			}
		.team-param__item_lite {
			width: auto;
		}
	.grade-wrapper__name {
		width: calc( 100% - 124px );
		padding: 0 16px 0 0;
	}
	.grade-wrapper__status {
		width: 124px;
		font-weight: 800;
	}
	.table-item__desc-hide span {
		margin: 0 6px 0 0;
	}
}

@media (max-width: 750px) {
	.team-personal {
		flex-wrap: wrap;
		align-items: flex-start;
		flex-direction: column;
		gap: 24px;
		width: 100%;
		min-width: unset;
	}
		.team-param {
			flex-direction: column;
			align-items: flex-start;
			gap: 16px;
			padding: 0;
			font-size: 10.6666px;
		}
			.team-param__item {
				flex-direction: row;
				align-items: flex-start;
			}
				.team-personal h1 {
					font-size: 20px;
				}
				.team-param__start span {
					width: auto;
				}
				.team-param__item-title, .team-param__start span.team-param__item-title {
					flex-shrink: 0;
					width: 124px;
				}
	.grade-wrapper {
		margin: 0 auto 96px auto;
		padding: 0 16px;
	}
		.grade-wrapper h2 {
			text-align: left;
		}
		.grade-wrapper__item {
			padding: 18px 16px;
			font-size: 16px;
		}
}
@media (max-width: 400px) {
	.team-param {
		font-size: 9px;
	}
	.team-param__item {
		gap: 8px;
	}
}
