.fame__prev {
	position: relative;
	z-index: 1;
	margin: 0 0 96px 0;
	padding: 146px 0 0 0;
	text-align: center;
}
	.fame__prev:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 50%;
		z-index: 3;
		transform: translateX(-50%);
		width: 130px;
		height: 122px;
		background-image: url(../../assets/images/hall-of-fame/wreath.svg);
		background-position: 50% 0;
		background-repeat: no-repeat;
		background-size: 100% auto;
	}
	.fame__prev:before {
		content: "";
		display: block;
		position: absolute;
		top: -16px;
		left: 50%;
		z-index: 2;
		transform: translateX(-50%);
		width: 506px;
		height: 220px;
		background-image: url(../../assets/images/hall-of-fame/glow.svg);
		background-position: 50% 0;
		background-repeat: no-repeat;
		background-size: 100% auto;
		fill: #65D8FF;
	}
	.fame__prev h1 {
		margin: 0 0 16px 0;
		color: #FFF;
		font-size: 42px;
		font-weight: 900;
		line-height: 120%;
	}
	.fame__prev p {
		max-width: 300px;
		margin: 0 auto;
		color: #FFF;
		font-size: 24px;
		line-height: 140%;
	}

	.fame-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 40px;
		width: 1208px;
		max-width: 100%;
		margin: 0 auto 200px auto;
		padding: 0 24px;
	}
		.fame-item {
			width: calc(50% - 20px);
			margin: 0 0 61px 0;
		}
			.fame-item__img {
				width: 100%;
				margin: 0 0 8px 0;
				border-radius: 24px;
			}
			.fame-item__name {
				margin: 0 0 4px 0;
				color: #FFF;
				font-size: 24px;
				font-weight: 900;
				line-height: 120%;
			}
			.fame-item__title {
				margin: 0 0 16px 0;
				color: #FFF;
				line-height: 140%;
			}
			.fame-item__tags {
				display: flex;
				flex-wrap: wrap;
				gap: 8px;
			}
				.fame-item__tag {
					padding: 8px 16px;
					border-radius: 40px;
					border: 1px solid #02ACE5;
					color: #FFF;
					text-align: center;
					font-size: 14px;
					font-weight: 600;
					line-height: 130%;
				}
	
	.do-you-want {
		position: relative;
		z-index: 1;
		margin: 0 auto 180px auto;
	}
		.do-you-want:after {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			transform: translate(-50%, -50%);
			width: 310px;
			height: 290px;
			background-color: #02ACE5;
			filter: blur(185px);
		}
		.do-you-want__wrapper {
			position: relative;
			z-index: 10;
			width: 597px;
			height: 302px;
			margin: 0 auto;
			padding: 48px 40px;
			text-align: center;
			border-radius: 24px;
			background-color: #3B3B3B;
		}
			.do-you-want h2 {
				margin: 0 0 24px 0;
				color: #02ACE5;
				font-size: 42px;
				font-weight: 900;
				line-height: 120%;
			}
			.do-you-want p {
				margin: 0 0 32px 0;
				color: #FFF;
				font-size: 24px;
				line-height: 140%;
			}
			.do-you-want__btn {
				margin: 0 auto;
			}
@media (max-width: 750px) {
	.fame__prev {
		margin: 0 0 40px 0;
		padding: 96px 0 0 0;
	}
		.fame__prev h1 {
			font-size: 32px;
		}
		.fame__prev p {
			font-size: 20px;
		}
		.fame__prev:after {
			width: 95px;
			height: 88px;
		}
		.fame__prev:before {
			width: 361px;
			height: 146px;
		}
	.fame-wrapper {
		gap: 32px;
		padding: 0 16px;
	}
		.fame-item {
			width: 100%;
			margin: 0;
		}
	.do-you-want {
		margin: 0 auto 96px auto;
		padding: 0 16px;
	}
		.do-you-want__wrapper {
			width: 100%;
		}
			.do-you-want h2 {
				margin: 0 0 16px 0;
				font-size: 32px;
			}
			.do-you-want p {
				font-size: 20px;
			}
		.do-you-want:after {
			transform: translate(-50%, 0);
		}
}