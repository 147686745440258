.resultsTabs {
    padding: 24px;
}
    .resultsTabs-wrapper, .resultsTabs {
        width: 100%;
        max-width: calc(var(--max-width, 1344px) + 48px);
        margin: 0 auto 56px auto;
        background: #333;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        flex-shrink: 0;
    }
    .resultsTabs-wrapper.mid {
        max-width: var(--max-width-mid, 1064px);
    }
        .resultsTabs-tab-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: 256px;
            padding: 8px 32px 8px 0;
            gap: 16px;
            border-radius: 16px;
            background: rgba(255, 255, 255, 0.04);
            cursor: pointer;
            box-shadow: 8px 8px 12px 0px rgba(35, 33, 33, 0.30);
        }
        .resultsTabs-tab-wrapper.active, .resultsTabs-tab-wrapper:hover {
            background-color: #02ACE5;
        }
            .resultsTabs-tab-wrapper .resultsTabs-tab-icon {
                opacity: 0.4;
            }
            .resultsTabs-tab-wrapper.active .resultsTabs-tab-icon, .resultsTabs-tab-wrapper:hover  .resultsTabs-tab-icon {
                opacity: 1;
            }
            .resultsTabs-tab-icon {
                display: flex;
                width: 52px;
                height: 48px;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
            }
            .resultsTabs-tab-text {
                color: #FFF;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
            }

    /* Создаем стилизованную полосу прокрутки */
    .resultsTabs::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        /* Ширина полосы прокрутки */
    }

    .resultsTabs::-webkit-scrollbar-thumb {
        background: rgba(2, 172, 229, 0.8);
        border-radius: 5px;
    }

    .resultsTabs::-webkit-scrollbar-thumb:hover {
        background: rgba(2, 172, 229, 1);
    }

    .resultsTabs::-webkit-scrollbar-track {
        background: rgba(2, 172, 229, 0.2);
    }
@media (max-width: 1400px) {
    .resultsTabs-tab-wrapper {
        width: calc(20% - 12.8px);
        padding: 8px 8px 8px 0;
    }
}
@media (max-width: 1100px) {
    .resultsTabs-tab-wrapper {
        justify-content: start;
        gap: 8px;
        padding: 8px;
    }
    .resultsTabs-tab-text {
        font-size: 16px;
    }
}
@media (max-width: 1000px) {
    .resultsTabs-wrapper, .resultsTabs {
        flex-direction: column;
        padding: 0 16px;
    }
    .resultsTabs-tab-wrapper {
        display: none;
        width: 100%;
        padding: 8px 32px;
    }
    .resultsTabs-tab-wrapper.active {
        display: flex;
        order: -1;
        position: relative;
    }
        .resultsTabs-tab-wrapper.active:before {
            content: "";
            display: block;
            position: absolute;
            right: 32px;
            top: 50%;
            width: 15px;
            height: 8px;
            margin: -4px 0 0 0;
            background-image: url(../../assets/icons/arrowDown.svg);
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: 100% auto;
            transition: all .3s;
        }
        .resultsTabs-tab-wrapper.active.toggle-arrow:before {
            transform: rotate(-180deg);
        }
        .resultsTabs-tab-text {
            font-size: 18px;
        }
}
@media (max-width: 750px) {
    .resultsTabs {
        margin: 0 0 56px 0;
        padding: 24px 16px;
    }
}