.tableFilters-filters_margin.center-position_804,
.tableFilters-filters_margin.center-position_1004,
.tableFilters-filters_margin.center-position_1344 {
    margin: 0 auto 2.5rem auto;
    padding: 0 24px;
}
    /* Фильтр и Поиск. Общая обертка */
    .tableFilters-filters-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 24px 8px;
        width: 100%;
    }
        /* Pop Up */
        .tableFilters-modal-full {
            position: relative;
            width: calc(100% - 48px);
            margin: 0 24px 24px 24px;
            padding: 0 0 16px 0;
        }
            /* Создаем стилизованную полосу прокрутки */
            .tableFilters-modal-full::-webkit-scrollbar {
                width: 3px;
                height: 3px;
            }
            .tableFilters-modal-full::-webkit-scrollbar-thumb {
                background: rgba(2, 172, 229, 0.8);
                border-radius: 5px;
            }
            .tableFilters-modal-full::-webkit-scrollbar-thumb:hover {
                background: rgba(2, 172, 229,1);
            }
            .tableFilters-modal-full::-webkit-scrollbar-track {
                background: rgba(2, 172, 229, 0.2);
            }

            .tableFilters-modal-wrapper {
                display: flex;
                width: 1392px;
                margin: 0 auto;
                padding: 0 24px;
                gap: 16px;
            }
            
                .tableFilters-show_tags {
                    text-wrap: nowrap;
                }

        /* Btn Open Filters */
        .tableFilters-show {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            padding: 8px 24px;
            border-radius: 100px;
            border: 1px solid var(--bg-blue, #02ACE5);
            color: #fff;
            font-size: 16px;
            cursor: pointer;
        }
            .tableFilters-show span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: var(--bg-blue, #02ACE5);
                font-size: 11px;
            }
    /* Набор кнопок для фильтров */
    .tableFilters__swipe-wrapper {
        order: 2;
        position: relative;
    }
    .tableFilters-filters-wrapper .tableFilters__lists {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        align-items: center;
        flex-grow: 1;
        position: relative;
        width: auto;
        margin: 0;
    }
    .center-position_804 .tableFilters__swipe-wrapper {
        order: 2;
    }
    .center-position_804_mini .tableFilters__swipe-wrapper {
         order: 1;
     }
    .center-position_1004 .tableFilters__swipe-wrapper {
        order: 1;
    }
        .tableFilters__lists .tableFilters__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            width: auto;
            padding: 12px 24px 12px 32px;
            font-size: 1em;
            line-height: 1.3;
            border-radius: 100px;
            border: 1px solid #02ACE5;
            cursor: pointer;
            opacity: .9;
        }
            .tableFilters__lists .tableFilters__item span {
                display: block;
                width: 100%;
                text-wrap: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .tableFilters__item:hover {
                opacity: 1;
            }
            .tableFilters__item:active {
                z-index: 102;
                opacity: 1;
            }
            .tableFilters__item:after {
                content: "";
                display: block;
                width: 14px;
                height: 14px;
                margin: 0 0 0 10px;
                background-image: url(../../../../assets/icons/arrow-bottom-blue.svg);
                background-position: 50%;
                background-size: 14px auto;
                background-repeat: no-repeat;
                transition: all .3s;
            }
            .tableFilters__item:hover, .tableFilters__item:active, .tableFilters__item.active {
                opacity: 1;
            }
            .tableFilters__item.active:after, .tableFilters__item:active:after, .tableFilters__item:focus:after, .tableFilters__item:target:after {
                transform: rotate(180deg);
            }

.tableFilters-filters-filter-button {
    display: flex;
    width: 137px;
    height: 46px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    border: 1px solid var(--bg-blue, #02ACE5);
    cursor: pointer;
}

.tableFilters-filters-filter-button-text {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

    /* Строка поиска */
    .tableFilters__filters-input {
        flex-grow: 1;
        position: relative;
    }
    .center-position_804 .tableFilters__filters-input {
        order: 1;
        width: 100%;
    }
    .center-position_804_mini .tableFilters__filters-input {
        width: auto;
        order: 2;
    }
    .center-position_1004 .tableFilters__filters-input {
        order: 2;
    }
        .tableFilters-filters-img {
            position: absolute;
            top: 14px;
            left: 24px;
        }
        .tableFilters-filters-input {
            display: flex;
            width: 100%;
            height: 46px;
            font-family: 'Inter', sans-serif;
            font-size: 1em;
            align-items: center;
            gap: 16px;
            border-radius: 60px;
            background-color: var(--bg-main, #333);
            padding: 0px 58px;
            border: 1px solid rgba(255, 255, 255, 0.60);
            color: rgba(255, 255, 255, 0.6);
        }
            .tableFilters-filters-input:active, .tableFilters-filters-input:focus {
                border: 1px solid #02ACE5;
            }

    /* Переключатель вида таблицы */
    .tableFilters-more {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    .center-position_804 .tableFilters-more {
        order: 3;
        margin-left: auto;
    }
    .center-position_1004 .tableFilters-more {
        order: 3;
        margin-left: 24px;
    }
    
        .tableFilters-more__title {
            font-family: 'Inter', sans-serif;
            white-space: nowrap;
        }
            .tableFilters-more__input[type=checkbox] {
                display: none;
                height: 0;
                width: 0;
                visibility: hidden;
            }
            .tableFilters-more__label {
                display: block;
                position: relative;
                width: 40px;
                height: 20px;
                border-radius: 100px;
                cursor: pointer;
                background: rgba(255, 255, 255, 0.2);
            }
                .tableFilters-more__label:after {
                    content: '';
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    width: 14px;
                    height: 14px;
                    background-color: #fff;
                    border-radius: 50%;
                    transition: 0.7s;
                }
                .tableFilters-more__input:checked+.tableFilters-more__label {
                    background-color: rgba(2, 172, 229, 1);
                }
                .tableFilters-more__input:checked+.tableFilters-more__label:after {
                    left: 23px
                }


.filter-shadow {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(44, 44, 44, 0.6);
}
.filter-shadow.active {
    display: block;
}

.filter-modal-common {
    overflow: auto;
    position: absolute;
    left: 0;
    top: 60px;
    z-index: 101;
    max-width: 320px;
    background-color: #3A3A3A;
    border-radius: 24px;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 24px;
}

.filter-show-block {
    display: block;
}

.filter-modal-close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #FFF;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 32px;
    cursor: pointer;
}
.filter-modal-close_img {
    width: 13px;
    height: 13px;
    margin: 2px 0 0 8px;
}
.filter-modal-label {
    color: #FFF;
    font-size: 24px;
    font-weight: 900;
    line-height: 140%;
    margin-bottom: 16px;
}

.filter-modal-button {
    padding: 12px 24px;
    border-radius: 100px;
    border: 1px solid #02ACE5;
    color: #FFF;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    transition: all .3s;
}
    .filter-btn_clear:hover {
        background-color: #12BFFA;
    }
    .filter-btn_save:hover {
        background-color: #12BFFA;
    }
    .filter-btn_save:active, .filter-btn_clear:active {
        -webkit-transform: scale(.97);
        transform: scale(.97);
    }

.filter-modal-city__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 0 16px 0;
}
.filter-modal-city__wrapper ~ .filter-modal-label {
    margin: 32px 0 16px 0;
}
     /* для элемента input c type="checkbox" */
    .filter-modal-city__checkbox {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    /* для элемента label, связанного с .custom-checkbox */
    .filter-modal-city__checkbox+label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }
    /* создание в label псевдоэлемента before со следующими стилями */
    .filter-modal-city__checkbox+label::before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        margin: 0 8px 0 0;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid rgba(2, 172, 229, 1);
        border-radius: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }
    /* стили при наведении курсора на checkbox */
    .filter-modal-city__checkbox:not(:disabled):not(:checked)+label:hover::before {
        border-color: #b3d7ff;
    }
    /* стили для активного чекбокса (при нажатии на него) */
    .filter-modal-city__checkbox:not(:disabled):active+label::before {
        background-color: #b3d7ff;
        border-color: #b3d7ff;
    }
    /* стили для чекбокса, находящегося в фокусе */
    .filter-modal-city__checkbox:focus+label::before {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
    .filter-modal-city__checkbox:focus:not(:checked)+label::before {
        border-color: #80bdff;
    }
    /* стили для чекбокса, находящегося в состоянии checked */
    .filter-modal-city__checkbox:checked+label::after {
        content: "";
        position: absolute;
        left: 3px;
        top: 8px;
        width: 9px;
        height: 9px;
        border-radius: 100%;
        background-color: rgba(2, 172, 229, 1);
    }
    /* стили для чекбокса, находящегося в состоянии disabled */
    .filter-modal-city__checkbox:disabled+label::before {
        background-color: #e9ecef;
    }

    .filter-modal-city {
        color: #FFF;
        font-size: 18px;
        line-height: 140%;
    }


/* Calendar */
.calendar-period {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    margin: 0 0 24px 0;
    padding: 0 16px;
    border-radius: 30px;
    border: 1px solid var(--bg-blue, #02ACE5);
}
    .calendar-period__icon {
        width: 33px;
        height: 17px;
        padding: 0 16px 0 0;
        background-image: url(../../../../assets/icons/calendar.svg);
        background-position: 0 50%;
        background-repeat: no-repeat;
        background-size: auto 100%;
    }
    .calendar-period__term {
        flex-grow: 1;
        height: 100%;
        padding: 0 16px 0 16px;
        font-size: 1em;
        line-height: 40px;
        border-left: 1px rgba(255,255,255,0.6) solid;
        text-wrap: nowrap;
        overflow: hidden;
    }
.calendar-period__month {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0 0 16px 0;
}
    .calendar-period__month-name {
        font-weight: 600;
    }
    .calendar-period__month-arrow {
        display: block;
        width: 8px;
        height: 14px;
    }
.filter-modal-date-wrapper {
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: 16px;
    height: 40px;
    border-radius: 30px;
    border: 1px solid #02ACE5;
    margin-bottom: 32px;
}

.filter-modal-date-details-wrapper {
    margin-bottom: 32px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.04);
    width: 100%;
    padding-bottom: 20px;
    padding-top: 20px;
}

.filter-modal-date-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5px 0px;
}

.filter-modal-date-cell {
    width: 60px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}



.filter-modal-button-wrapper {
    display: flex;
    gap: 8px;
    width: 100%;
    margin-top: 30px;
}
.filter-modal-button-wrapper_mini {
    width: 194px;
    min-width: unset;
    margin: 24px auto 0 auto;
}
    .filter-modal-button {
        width: calc(50% - 4px);
        padding: 12px 12px;
        border-radius: 100px;
        text-align: center;
        border: 1px #02ACE5 solid;
    }
    .filter-modal-button-wrapper_mini .filter-modal-button {
        padding: 8px;
        font-size: 14px;
    }
    .filter-btn_save {
        background-color: #02ACE5;
    }



@media (max-width: 1392px) {
        .tableFilters-modal-wrapper {
            width: auto;
        }
}
@media (max-width: 1000px) {
    .center-position_1004 .tableFilters__filters-input {
        order: 1;
        width: 100%;
    }
}
@media (max-width: 820px) {
    .tableFilters-filters_margin.center-position_1004,
    .tableFilters-filters_margin.center-position_1344,
    .tableFilters-filters_margin.center-position_804 {
        padding: 0 16px;
    }
}
@media (max-width: 750px) {
    .center-position_804_mini .tableFilters__filters-input {
        order: 1;
    }
    .tableFilters__swipe-wrapper {
        width: 100%;
    }
        .tableFilters__lists .tableFilters__item {
            flex-grow: 1;
        }
    .tableFilters-wrapper {
        padding: 0 16px;
    }
    .tableFilters-filters-filter-button {
        width: auto;
        padding: 13px 30px;
    }
    .tableFilters-filters-filter-button-text {
        display: none;
    }
    .filter-modal-common {
        left: 0;
        right: 0;
        width: 100%;
        max-width: 100%;
    }
        /* Создаем стилизованную полосу прокрутки */
        .filter-modal-common::-webkit-scrollbar {
            width: 1px;
            height: 1px;
        }
        .filter-modal-common::-webkit-scrollbar-thumb {
            background: rgba(2, 172, 229, 0.8);
            border-radius: 5px;
        }
        .filter-modal-common::-webkit-scrollbar-thumb:hover {
            background: rgba(2, 172, 229, 1);
        }
        .filter-modal-common::-webkit-scrollbar-track {
            background: rgba(2, 172, 229, 0.2);
        }

    .tableFilters__lists {
        gap: 4px;
        width: 100%;
    }
}

@media (max-width: 320px) {
    .filter-modal-button-wrapper {
        flex-direction: column;
    }
        .filter-modal-button {
            width: 100%;
        }
}