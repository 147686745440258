.player-ratings__period {
	display: flex;
	align-items: center;
	width: 530px;
	margin: 0 auto 40px auto;
	border-radius: 30px;
	border: 1px var(--bg-blue, #02ACE5) solid;
	font-size: 1.25em;
	font-weight: 900;
	line-height: 1.2;
	text-align: center;
	overflow: hidden;
}
	.player-ratings__period-month {
		width: 50%;
		padding: 16px 64px;
		cursor: pointer;
		transition: all .3s;
	}
	.player-ratings__period-full {
		width: 50%;
		padding: 16px 64px;
		cursor: pointer;
		transition: all .3s;
	}
		.player-ratings__period-month:hover, .player-ratings__period-full:hover, .player-ratings__period-month.active,
		.player-ratings__period-full.active {
			background-color: var(--bg-blue, #02ACE5);
		}

@media (max-width: 750px) {
	.player-ratings__period {
		align-items: stretch;
		width: 100%;
		font-size: 1em;
	}
		.player-ratings__period-full, .player-ratings__period-month {
			padding: 16px 24px;
		}
}