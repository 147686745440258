.achive-wrapper {
	width: 1392px;
	max-width: 100%;
	margin: 0 auto;
	padding: 0 24px;
}
	.achive-prev {
		width: 100%;
		margin: 0 0 64px 0;
		text-align: center;
	}
		.achive-prev h1 {
			margin: 0 0 16px 0;
			color: #FFF;
			font-family: Mulish;
			font-size: 42px;
			font-weight: 900;
			line-height: 120%;
		}
		.achive-prev p {
			max-width: 536px;
			margin: 0 auto;
			color: #FFF;
			font-family: Mulish;
			font-size: 24px;
			line-height: 140%;
		}

	.achive-legend {
		display: flex;
		gap: 40px;
		width: 1119px;
		max-width: 100%;
		margin: 0 auto 180px auto;
		padding: 0 24px;
	}
		.achive-legend__column {
			display: flex;
			flex-direction: column;
			width: calc( 50% - 20px );
			padding: 32px;
			border-radius: 24px;
			background: rgba(255, 255, 255, 0.04);
			color: #fff;
		}
			.achive-legend__belt-img {
				width: 217px;
				height: auto;
				margin: 0 0 49px 0;
			}
			.achive-legend__power-img {
				width: 156px;
				height: auto;
				margin: 0 0 38px 0;
			}
			.achive-legend__column h3 {
				margin: auto 0 8px 0;
				color: #FFF;
				font-size: 24px;
				font-weight: 900;
				line-height: 140%;
			}
			.achive-legend__column p {
				margin: 0;
				color: #FFF;
				font-size: 16px;
				line-height: 140%;
			}
	.achive-power {
		width: 100%;
		margin: 0 0 180px 0;
	}
		.achive-power h2 {
			margin: 0 0 64px 0;
			color: #FFF;
			text-align: center;
			font-size: 42px;
			font-size: clamp(32px, 15.4366197183px + 2.1126760563vw, 42px);
			font-weight: 900;
			line-height: 120%;
		}
			.achive-power h2 span {
				display: block;
			}
		.achive-power__formulas {
			display: flex;
			gap: 40px;
		}
			.achive-power__formulas-column {
				display: flex;
				align-items: center;
				gap: 24px;
				width: calc(50% - 20px);
				padding: 32px;
				border-radius: 24px;
				background: rgba(255, 255, 255, 0.04);
			}
				.formulas-first__result {
					flex-shrink: 0;
					width: 220px;
					color: #FFF;
					font-size: 24px;
					font-weight: 900;
					line-height: 120%;
				}
				.formulas-first__equal {
					color: #FFF;
					font-size: 40px;
					font-weight: 900;
					line-height: 140%;
				}
				.formulas-first__form {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					position: relative;
					margin: 0 0 0 24px;
					padding: 0 8px;
					color: #FFF;
					text-align: center;
					font-size: 24px;
					line-height: 140%;
				}
					.formulas-first__form:after {
						content: ")";
						display: block;
						position: absolute;
						right: -28px;
						top: calc(50% - 8px);
						height: 100px;
						transform: translateY(-50%);
						color: rgba(255, 255, 255, 0.60);
						font-size: 100px;
						line-height: 1;
						font-weight: 200;
					}
					.formulas-first__form:before {
						content: "(";
						display: block;
						position: absolute;
						left: -28px;
						top: calc(50% - 8px);
						height: 100px;
						transform: translateY(-50%);
						color: rgba(255, 255, 255, 0.60);
						font-size: 100px;
						line-height: 1;
						font-weight: 200;
					}
					.formulas-first__divisible {
						position: relative;
						margin: 0 0 20px 0;
					}
						.formulas-first__divisible:before {
							content: "2";
							position: absolute;
							right: -42px;
							top: -28px;
							color: #FFF;
							font-size: 24px;
							line-height: 140%;
						}
					.formulas-first__divider {
						position: relative;
					}
						.formulas-first__divider:after {
							content: "";
							display: block;
							position: absolute;
							top: -11px;
							left: 0;
							width: 100%;
							height: 4px;
							background: rgba(255, 255, 255, 0.60);
						}
				
				.formulas-second__result {
					color: #FFF;
					font-size: 24px;
					font-weight: 900;
					line-height: 120%;
				}
				.formulas-second__equal {
					color: #FFF;
					font-size: 40px;
					font-weight: 900;
					line-height: 140%;
				}
				.formulas-second__form {
					display: flex;
					flex-direction: column;
					color: #FFF;
					font-size: 24px;
					line-height: 140%;
				}
					.formulas-second__divisible {}
					.formulas-second__divider {}
	
	.archive-belts {
		width: 100%;
		margin: 0 0 180px 0;
	}
		.archive-belts h2 {
			margin: 0 0 64px 0;
			color: #FFF;
			text-align: center;
			font-size: 42px;
			font-weight: 900;
			line-height: 120%;
		}
		.archive-belts__wrapper {
			display: flex;
			flex-wrap: wrap;
			gap: 64px;
			width: 896px;
			max-width: 100%;
			margin: 0 auto;
			padding: 0 24px;
		}
			.archive-belts__row {
				position: relative;
				width: 88px;
				padding: 40px 0 20px 0;
				color: #FFF;
				text-align: center;
				font-size: 24px;
				line-height: 140%;
				background-size: 88px auto;
				background-repeat: no-repeat;
				background-position: 50% 0;
			}
			.archive-belts__honor:before {
				content: "";
				display: block;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 24px;
				height: 24px;
				background-image: url(../../assets/icons/achives/belts-honor.svg);
				background-repeat: no-repeat;
				background-size: 24px auto;
				background-position: 50% 100%;
			}
			.archive-belts__row:nth-child(1) {
				background-image: url(../../assets/icons/achives/belts/100.svg);
			}
			.archive-belts__row:nth-child(2) {
				background-image: url(../../assets/icons/achives/belts/300.svg);
			}
			.archive-belts__row:nth-child(3) {
				background-image: url(../../assets/icons/achives/belts/600.svg);
			}
			.archive-belts__row:nth-child(4) {
				background-image: url(../../assets/icons/achives/belts/1000.svg);
			}
			.archive-belts__row:nth-child(5) {
				background-image: url(../../assets/icons/achives/belts/1500.svg);
			}
			.archive-belts__row:nth-child(6) {
				background-image: url(../../assets/icons/achives/belts/2100.svg);
			}
			.archive-belts__row:nth-child(7) {
				background-image: url(../../assets/icons/achives/belts/2800.svg);
			}
			.archive-belts__row:nth-child(8) {
				background-image: url(../../assets/icons/achives/belts/3600.svg);
			}
			.archive-belts__row:nth-child(9) {
				background-image: url(../../assets/icons/achives/belts/4500.svg);
			}
			.archive-belts__row:nth-child(10) {
				background-image: url(../../assets/icons/achives/belts/5500.svg);
			}
			.archive-belts__row:nth-child(11) {
				background-image: url(../../assets/icons/achives/belts/6600.svg);
			}
			.archive-belts__row:nth-child(12) {
				background-image: url(../../assets/icons/achives/belts/7800.svg);
			}
			.archive-belts__row:nth-child(13) {
				background-image: url(../../assets/icons/achives/belts/9100.svg);
			}
			.archive-belts__row:nth-child(14) {
				background-image: url(../../assets/icons/achives/belts/10500.svg);
			}
			.archive-belts__row:nth-child(15) {
				background-image: url(../../assets/icons/achives/belts/12000.svg);
			}
			.archive-belts__row:nth-child(16) {
				background-image: url(../../assets/icons/achives/belts/13600.svg);
			}
			.archive-belts__row:nth-child(17) {
				background-image: url(../../assets/icons/achives/belts/15300.svg);
			}
			.archive-belts__row:nth-child(18) {
				background-image: url(../../assets/icons/achives/belts/17100.svg);
			}

	.archive-honors {
		width: 1208px;
		max-width: 100%;
		margin: 0 auto 180px auto;
		padding: 0 24px;
	}
		.archive-honors h2 {
			position: relative;
			margin: 0 0 64px 0;
			color: #FFF;
			text-align: center;
			font-size: 42px;
			font-weight: 900;
			line-height: 120%;
		}
		.archive-honors h2.archive-honors_1:before {
			content: "";
			display: block;
			margin: 0 auto 16px auto;
			width: 65px;
			height: 65px;
			background-image: url(../../assets/icons/achives/belts-honor.svg);
			background-position: 50% 0;
			background-repeat: no-repeat;
			background-size: 65px auto;
		}
		.archive-honors__wrapper {
			display: flex;
			flex-wrap: wrap;
			gap: 40px;
		}
			.archive-honors__item {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				width: calc(50% - 20px);
				padding: 32px;
				border-radius: 24px;
				background-color: rgba(255, 255, 255, 0.04);
				background-repeat: no-repeat;
				background-position: 32px 32px;
				background-size: 88px 36px;
			}
			.archive-honors__item_1000 {
				background-image: url(../../assets/icons/achives/belts/1000.svg);
			}
			.archive-honors__item_2100 {
				background-image: url(../../assets/icons/achives/belts/2100.svg);
			}
			.archive-honors__item_4500 {
				background-image: url(../../assets/icons/achives/belts/4500.svg);
			}
			.archive-honors__item_7800 {
				background-image: url(../../assets/icons/achives/belts/7800.svg);
			}
			.archive-honors__item_12000 {
				background-image: url(../../assets/icons/achives/belts/12000.svg);
			}
			.archive-honors__item_17100 {
				background-image: url(../../assets/icons/achives/belts/17100.svg);
			}
				.archive-honors__item p {
					width: calc(100% - 232px);
					padding: 62px 0 0 0;
					color: #FFF;
					font-size: 16px;
					line-height: 140%;
				}
				.archive-honors__item img {
					width: 200px;
					border-radius: 18px;
				}
	.archive-honors_2 {}
		.archive-honors h2.archive-honors_2 {
			max-width: 388px;
			margin: 0 auto 64px auto;
		}
		.archive-honors h2.archive-honors_2:before {
			content: "";
			display: block;
			margin: 0 auto 16px auto;
			width: 106px;
			height: 48px;
			background-image: url(../../assets/icons/achives/count.svg);
			background-position: 50% 0;
			background-repeat: no-repeat;
			background-size: 106px auto;
		}
			.archive-honors__wrapper_2 {
				display: flex;
				flex-wrap: wrap;
				gap: 40px;
			}
				.archive-honors_2__item {
					width: calc(33% - 26.6666px);
					padding: 32px;
					border-radius: 24px;
					background: rgba(255, 255, 255, 0.04);
				}
					.archive-honors_2__item h3 {
						margin: 0 0 16px 0;
						color: #FFF;
						font-size: 24px;
						font-weight: 900;
						line-height: 120%;
					}
					.archive-honors_2__item p {
						margin: 0 0 24px 0;
						color: #FFF;
						font-size: 16px;
						line-height: 140%;
					}
					.archive-honors_2__item img {
						width: 100%;
						border-radius: 18px;
					}

@media (max-width: 1350px) {
	.achive-power__formulas-column {
		justify-content: center;
	}
	.formulas-first__form {
		font-size: 18px;
	}
	.formulas-first__result {
		width: 160px;
		font-size: 18px;
	}
	.formulas-second__result {
		font-size: 18px;
	}
	.formulas-second__form {
		font-size: 18px;
	}
	.formulas-second__equal,
	.formulas-first__equal {
		font-size: 28px;
	}
	.formulas-first__divisible:before {
		right: -32px;
		font-size: 18px;
	}
}
@media (max-width: 1000px) {
	.achive-power,
	.achive-legend {
		margin: 0 0 96px 0;
	}
		.achive-power__formulas {
			flex-direction: column;
			align-items: center;
		}
			.achive-power__formulas-column {
				width: 100%;
				max-width: 500px;
			}
	.archive-belts__wrapper {
		width: 440px;
	}
	.archive-honors__item {
		flex-direction: column;
		gap: 16px;
	}
		.archive-honors__item p {
			width: 100%;
			padding: 48px 0 0 0;
		}
	.archive-honors {
		margin: 0 0 96px 0;
	}
	.archive-honors_2__item {
		width: 100%;
		padding: 24px;
	}
	.archive-honors h2.archive-honors_2 {
		max-width: 372px;
	}
}
@media (max-width: 750px) {
	.achive-wrapper {
		padding: 0 16px;
	}

	.achive-legend {
		flex-direction: column;
		gap: 32px;
		padding: 0;
	}
		.achive-prev {
			margin: 0 0 40px 0;
		}
			.achive-prev h1 {
				font-size: 32px;
			}
			.achive-prev p {
				font-size: 20px;
			}
		.achive-legend__column {
			width: 100%;
			padding: 24px;
		}
			.achive-legend__belt-img {
				width: 129px;
				margin: 0 0 16px 0;
			}
			.achive-legend__column h3 {
				margin: 0 0 16px 0;
			}

			.achive-legend__power-img {
				width: 120px;
				margin: 0 0 16px 0;
			}

	.achive-power__formulas {
		gap: 32px;
	}
	.achive-power h2 {
		margin: 0 0 40px 0;
	}
	.achive-power__formulas-column {
		flex-direction: column;
		text-align: center;
		gap: 8px;
		padding: 24px;
	}
		.formulas-first__result,
		.formulas-second__result {
			font-size: 24px;
			line-height: 140%;
		}
		.formulas-second__equal,
		.formulas-first__equal {
			font-size: 30px;
		}
		.formulas-first__form,
		.formulas-second__form {
			margin: 0;
			font-size: 16px;
			line-height: 140%;
		}
			.formulas-first__form:before,
			.formulas-first__form:after {
				font-size: 60px;
				font-weight: 200;
				top: -5px;
				transform: unset;
			}
			.formulas-first__divisible:before {
				right: -44px;
				top: -5px;
			}
	.archive-belts {
		margin: 0 0 96px 0;
	}
		.archive-belts h2 {
			margin: 0 0 40px 0;
			font-size: 32px;
		}
		.archive-belts__wrapper {
			gap: 24px;
			width: 100%;
			padding: 0;
		}
			.archive-belts__row {
				width: calc(33.3333% - 16px);
			}
	.archive-honors {
		padding: 0;
	}
		.archive-honors__item {
			width: 100%;
			padding: 24px;
			background-position: 24px 24px;
		}
			.archive-honors h2 {
				margin: 0 0 40px 0;
				font-size: 23px;
			}
	
	.archive-honors__wrapper_2 {
		flex-direction: column;
	}
		.archive-honors_2__item {
			width: 100%;
		}
			.archive-honors__item img {
				width: 100%;
			}
	.archive-honors h2 {
		font-size: 32px;
	}
}
@media (max-width: 400px) {
	.archive-belts__row {
		background-size: 100% auto;
	}
}