.TableFilters {
    width: 100%;
}
    .tableWithFilters-show-more {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 220px;
        height: 50px;
        margin: 0 auto;
        border-radius: 100px;
        background:  #02ACE5;
        color: #FFF;
        text-align: center;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 56px;
        cursor: pointer;
    }
