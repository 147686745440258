
.shadow {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(44, 44, 44, 0.6);
  }
  .shadow.active {
    display: block;
  }

  .modal-common {
    overflow: auto;
    font-family: Mulish;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 101;
    max-width: 60vw;
    min-width: 680px;
    max-height: 80vh;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 24px;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 40px;
    color: #fff;
    background-color: #333;
  }

  .show-block {
    display: block;
  }
