.table-mobi {
	width: 100%;
	margin: 0 0 40px 0;
	padding: 0 16px;
}
	.table-mobi__header {
		display: flex;
		align-items: center;
		gap: 8px;
		margin: 0 0 16px 0;
		padding: 16px 32px 16px 32px;
		border-radius: 30px;
		background: #02ACE5;
		color: #fff;
		font-weight: 700;
		line-height: 140%;
	}
	.table-mobi__two-col_bigpadding .table-mobi__header {
		padding: 16px 48px 16px 32px;
	}
	.mobi-table__two-col_leftcentering .table-mobi__hide {
		display: none;
		padding: 16px 38px 16px 32px;
	}
		.mobi-table__two-col_leftcentering .table-mobi__string_title,
		.mobi-table__two-col_leftcentering .table-mobi__string_param {
			width: 50%;
			text-align: left;
		}
			.table-item__desc-hide {
				display: flex;
			}
		.mobi-table__two-col_leftcentering .table-mobi__string {
			gap: 8px;
		}
		.table-mobi__header_rel {
			position: relative;
		}
		.table-mobi__header_team {
			position: relative;
			flex-grow: 1;
		}
		.table-mobi__header_scores {
			flex-shrink: 0;
			position: relative;
			width: 68px;
		}
			.table-mobi__header_arrow {
				display: inline-block;
				width: 10px;
				height: 6px;
				margin: -2px 0 0 4px;
				padding-bottom: 10px;
				background-image: url(../../../assets/icons/arrowDown.svg);
				background-size: 100% auto;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				cursor: pointer;
			}
	
	.table-mobi__item {
		width: 100%;
		margin: 0 0 16px 0;
		border-radius: 30px;
	}
	.table-mobi__item:nth-child(2n) {
		background-color: rgba(255, 255, 255, 0.04);
	}
		.table-mobi__title {
			display: flex;
			align-items: start;
			gap: 8px;
			position: relative;
			padding: 16px 38px 16px 32px;
			font-size: 16px;
			line-height: 140%;
			color: #fff;
			cursor: pointer;
			transition: all .3s;
		}
		.table-mobi__two-col_bigpadding .table-mobi__title {
			padding: 16px 48px 16px 32px;
		}
			.table-mobi__title:after {
				position: absolute;
				top: 24px;
				right: 16px;
				flex-shrink: 0;
				content: "";
				display: block;
				transform: rotate(-90deg);
				width: 14px;
				height: 8px;
				background-image: url(../../../assets/icons/arrow-bottom-blue.svg);
				background-position: 50%;
				background-repeat: no-repeat;
				background-size: 100% auto;
				transition: all .3s;
			}
			.table-mobi__item.active .table-mobi__title:after {
				transform: rotate(0);
			}
	.table-mobi__hide {
		display: none;
		padding: 16px 48px 16px 106px;
		color: #fff;
	}
		.table-mobi__two-col_bigpadding .table-mobi__hide {
			padding: 16px 48px 16px 32px;
		}
		.table-mobi__item.active .table-mobi__hide {
			display: block;
		}
		.table-mobi__item.active {
			background-color: rgba(255, 255, 255, 0.04);
		}
			.table-mobi__first-link .table-mobi__item.active .table-mobi__title span:nth-child(1) {
				color: #02ACE5;
				line-height: 1.4;
				text-decoration-line: underline;
			}
			.table-mobi__second-link .table-mobi__item.active .table-mobi__title span:nth-child(2) {
				color: #02ACE5;
				line-height: 1.4;
				text-decoration-line: underline;
			}
		.table-mobi__string {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 16px;
			margin: 0 0 16px 0;
			padding: 4px 0;
		}
			.table-mobi__string_title {
				max-width: 50%;
				font-weight: 700;
				line-height: 140%;
			}
			.table-mobi__string_param {
				max-width: 50%;
				text-align: right;
				line-height: 140%;
			}
			.table-mobi__two-col_right-89 .table-mobi__string_param {
				width: 89px;
				text-align: center;
			}
			.table-mobi__two-col_right-89 .table-mobi__title span:nth-child(2) {
				flex-shrink: 0;
			}
				.string_img_1000 {
					width: 56px;
					height: 30px;
					background-image: url(../../../assets/icons/achives/belts/1000.svg);
					background-size: 100% auto;
					background-repeat: no-repeat;
					background-position: 100% 50%;
				}
				.string_img_17100 {
					width: 56px;
					height: 30px;
					background-image: url(../../../assets/icons/achives/belts/17100.svg);
					background-size: 100% auto;
					background-repeat: no-repeat;
					background-position: 100% 50%;
				}
				.table-mobi__string_param img {
					margin: 0 auto;
				}
				.table-mobi__exp-center .table-mobi__string_param img {
					margin: 0 -6px 0 0;
				}
@media (max-width: 400px) {
	.table-mobi__header,
	.table-mobi__title {
		padding: 12px 16px 12px 16px;
		font-size: 14px;
	}
	.table-mobi__title {
		align-items: center;
	}
	.table-mobi__title:after {
		right: 8px;
		top: 50%;
		margin: -3px 0 0 0;
		width: 10px;
		height: 6px;
	}
	.table-mobi__hide {
		padding: 16px 24px 16px 90px;
		font-size: 14px;
	}
	.mobi-table__two-col_leftcentering .table-mobi__hide {
		padding: 16px;
	}
}