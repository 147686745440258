.table-overflow {
    width: 100%;
}
    .table-wrapper {
        width: 100%;
        max-width: 1536px;
        margin: 0 auto;
    }
    .table-small .table-wrapper {
        width: 852px;
        padding: 0 24px;
    }
    .table-middle .table-wrapper {
        width: 1052px;
        max-width: 100%;
        padding: 0 24px;
    }
    .table-normal .table-wrapper {
        width: calc(var(--max-width, 1344px) + 48px);
        max-width: 100%;
        padding: 0 24px;
    }
    .table-normal_2 .table-wrapper {
        width: calc(var(--max-width, 1344px) + 48px);
        max-width: unset;
        padding: 0 24px;
    }
    .table-normal_middle .table-wrapper {
        width: 1144px;
        max-width: 100%;
        padding: 0 24px;
    }
    .table-big .table-wrapper {
        width: calc(var(--max-width, 1536px) + 48px);
        max-width: 100%;
        padding: 0 24px;
    }
    
        .table-overflow::-webkit-scrollbar {
            width: 3px;
            height: 6px;
        }
    
        .table-overflow::-webkit-scrollbar-thumb {
            background: #999;
            border-radius: 5px;
        }
    
        .table-overflow::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    
        .table-overflow::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        .table-header-wrapper {
            display: flex;
            position: sticky;
            top: 0;
            left: 0;
            padding: 0 24px;
            justify-content: space-between;
            align-items: center;
            background: #02ACE5;
            gap: 24px;
            border-radius: 30px;
            /* margin-bottom: 24px; */
        }
            .table-header-cell-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-shrink: 0;
                font-weight: 700;
                line-height: 1.4;
                text-align: center;
                padding: 12px 0px;
                cursor: pointer;
            }
            .table-header__title {
                text-wrap: wrap;
            }
    .my-table {
        width: 100%;
        overflow: auto;
    }
.table-header-cell-sort-icon {
    display: block;
    width: 11px;
    margin: 1px 0 0 5px;
    transition: all .3s;
}

.table-body-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    padding: 12px 24px;
    border-radius: 30px;
    background-color: var(--bg-main, #333);
    cursor: pointer;
}
    .table-body-wrapper:hover, .table-body-wrapper:nth-child(2n+1):hover {
        background-color: #2d2b2b;
    }
.table-normal_2 .table-body-wrapper, .table-normal_2 .table-header-wrapper {
    gap: 18px;
}
.table-body-wrapper:nth-child(2n+1) {
    background-color: rgba(255, 255, 255, 0.04);
}

    .table-body-cell-wrapper {
        line-height: 1.4;
        flex-shrink: 0;
    }
        .table-body-cell-wrapper img {
            width: 56px;
            height: auto;
        }
    .table-item_center {
        text-align: center;
        justify-content: center;
    }
    .table-item_left {
        text-align: left;
        justify-content: start;
    }
    .table-item_right {
        text-align: right;
        justify-content: end;
    }
    .table-item_padding {
        padding-right: 16px;
    }
    .table-header-wrapper .table-item_padding {
        padding: 12px 0;
    }
    .table-item_prop-width {
        flex-shrink: 1;
        width: 100%;
        min-width: 164px;
        max-width: 257px;
    }
    .table-item_balance-width {
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 100px;
    }

    .table-header-cell-wrapper.undefined {
        justify-content: center;
    }
        .table-header-cell-wrapper.undefined img {
            display: none;
        }
    .table-body-cell-wrapper.undefined {
        text-align: center;
    }
    .table-mobi__content .table-item_ellipsis {
        flex-shrink: 0;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .table-item_mobi-score {
        flex-shrink: 0;
        padding: 0 10px 0 0;
    }
    .table-mobi__header .table-item_mobi-score {
        padding: 0 15px 0 0;
    }
    .table__margin-bottom {
        margin: 0 0 96px 0;
    }
    .hyphens-css {
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        padding-right: 8px;
        overflow: hidden;
    }
@media (max-width: 1600px) {
    .table-overflow__1600 {
        width: 100%;
    }
    .table-overflow__1600 .table-wrapper {
        width: 100%;
        max-width: unset;
    }
}
@media (max-width: 1536px) {
    .table-header-wrapper, .table-body-wrapper {
        border-radius: 0;
    }
    .table-small .table-header-wrapper, .table-small .table-body-wrapper,
    .table-middle .table-header-wrapper, .table-middle .table-body-wrapper,
    .table-normal .table-header-wrapper, .table-normal .table-body-wrapper,
    .table-normal_2 .table-header-wrapper, .table-normal_2 .table-body-wrapper,
    .table-normal_middle .table-header-wrapper, .table-normal_middle .table-body-wrapper{
        border-radius: 30px;
    }
    .table-overflow__1600 .table-body-wrapper {
        border-radius: 0;
    }
    .table-mid .table-wrapper {
        width: 1536px;
        max-width: unset;
    }
    .table-mid,
    .table-overflow__nolength {
        overflow-x: auto;
    }   
    .table-overflow__nolength .table-wrapper {
        width: 100%;
        padding: 0 24px;
    }
}
        

@media (max-width: 1400px) {
    .table-wrapper {
        width: 1536px;
        max-width: unset;
    }
    .table-overflow {
        padding: 0 0 32px 0;
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
    }
    .table-normal,
    .table-normal_2,
    .table-overflow__1600,
    .table-normal_middle,
    .table-middle,
    .table-small {
        overflow: unset;
    }
    .table-overflow__nolength .table-wrapper {
        width: 100%;
        padding: 0 24px;
    }
    .table-normal .table-wrapper {
        width: 100%;
        min-width: 1220px;
        max-width: unset;
        padding: 0;
    }
    .table-normal_2 .table-wrapper {
        width: 100%;
        max-width: unset;
        padding: 0;
    }
    .table-normal_3 .table-wrapper {
        min-width: 1260px;
    }
    .table-normal .table-header-wrapper,
    .table-normal .table-body-wrapper,
    .table-normal_2 .table-header-wrapper,
    .table-normal_2 .table-body-wrapper {
        border-radius: 0;
    }
    .table-overflow__1600 .table-header-wrapper,
    .table-overflow__1600 .table-body-wrapper {
        gap: 12px;
    }
    .table-item_prop-width {
        flex-shrink: 1;
        width: 100%;
        min-width: 130px;
        max-width: 257px;
    }
    .my-table {
        overflow: auto;
    }
}
@media (max-width: 1320px) {
    .table-normal_2 {
        overflow-x: auto;
    }
}
@media (max-width: 1240px) {
    .table-normal {
        overflow-x: auto;
    }
}
@media (max-width: 1280px) {
    .table-overflow__1600 .table-wrapper {
        width: 1260px;
        padding: 0;
    }
    .table-overflow__1600 {
        overflow-x: auto;
    }
}
@media (max-width: 1200px) {
    .table-normal_middle .table-wrapper {
        width: 1140px;
        max-width: unset;
        padding: 0;
    }
    .table-normal_middle {
        overflow-x: auto;
    }
        .table-normal_middle .table-header-wrapper, .table-normal_middle .table-body-wrapper {
            border-radius: 0;
        }
    .table-overflow__nolength .table-wrapper {
        width: 100%;
        padding: 0 24px;
    }
}

@media (max-width: 1050px) {
    .table-middle .table-wrapper {
        width: 1052px;
        max-width: unset;
        padding: 0;
    }
    .table-middle {
        overflow-x: auto;
    }
    .table-middle .table-header-wrapper, .table-middle .table-body-wrapper {
        width: 100%;
        border-radius: 0;
    }
    .table-overflow__nolength .table-wrapper {
        width: 100%;
        padding: 0 24px;
    }

}
@media (max-width: 750px) {
    .table__margin-bottom {
        margin: 0 0 52px 0;
    }
}