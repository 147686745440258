.navbar-wrapper {
    width: 1392px;
    max-width: 100%;
    height: 70px;
    margin: 0 auto 32px auto;
    padding: 0 24px;
    background: #333;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
    .navbar-mobi {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .navbar-pages-wrapper {
        display: flex;
        flex-direction: row;
        gap: 28px;
        margin: 0 48px 0 auto;
        text-align: center;
        font-size: 14px;
    }
        .navbar-pages-page {
            cursor: pointer;
            transition: all .3s;
        }
            .navbar-pages-page:hover {
                color: rgb(2, 172, 229);
            }
    .navbar-icons-wrapper {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
        .navbar-icons-icon {
            width: 30px;
            height: 30px;
            cursor: pointer;
            opacity: .9;
            transition: all .3s;
        }
            .navbar-icons-icon:hover {
                opacity: 1;
            }
    .icon-mobi__menu {
        display: none;
        cursor: pointer;
    }

    .navbar-city {
        position: relative;
        margin: 0 48px 0 0;
    }
        .navbar-city__checked {
            display: flex;
            align-items: center;
            gap: 16px;
            cursor: pointer;
        }
            .navbar-city__icon {
                width: 15px;
                height: 16px;
                background-image: url(../../assets/icons/location.svg);
                background-position: 50%;
                background-size: 100% auto;
                background-repeat: no-repeat;
            }
            .navbar-city__name {
                padding: 4px 0;
                text-align: right;
                font-size: 1em;
                list-style: 1;
                border-bottom: 2px solid #02ACE5;
            }
        .navbar-city__select {
            position: absolute;
            top: 64px;
            right: 50%;
            transform: translateX(calc(50% + 16px));
            z-index: 1002;
            text-align: right;
            background-color: #3a3a3a;
            border-radius: 24px;
            padding: 24px;
            list-style: none;
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.0);
            transition: opacity 0.2s cubic-bezier(.895, .03, .685, .22);
            -webkit-transition: opacity 0.2s cubic-bezier(.895, .03, .685, .22);
        }
            .navbar-city__select::after {
                content: '';
                position: absolute;
                left: 50%;
                top: -19px;
                margin: 0 0 0 -10px;
                border: 10px solid transparent;
                border-bottom: 10px solid #3a3a3a;
            }
            .navbar-city__select li {
                margin: 0 0 8px 0;
                line-height: 1.35;
                text-wrap: nowrap;
            }
                .navbar-city__select li:hover {
                    cursor: pointer;
                    color: #02ace5;
                }

        .shadow-for-navbar {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1001;
            width: 100%;
            height: 100%;
            background-color: rgba(58, 58, 58,0.3);
        }
@media (max-width: 1200px) {
    .navbar-city {
        margin: 0 16px 0 0;
    }
    .navbar-pages-wrapper {
        align-items: center;
        margin: 0 16px 0 auto;
    }
    .logotype {
        margin: 0 16px 0 0;
    }
}
@media (max-width: 1000px) {
    .navbar-mobi {
        display: none;
    }
        .navbar-wrapper {
            margin: 0 auto 6px auto;
        }
            .navbar-pages-wrapper {
                display: block;
                margin: 0;
                text-align: right;
            }
            .icon-mobi__menu {
                display: block;
                width: 28px;
                height: 28px;
            }
                .icon-menu__line {
                    display: block;
                    transform-origin: 50% 50%;
                    transform: rotate(0deg) translateY(0px);
                    transition: transform 0.3s ease 0s;
                }
                .icon-menu_1 {
                    width: 28px;
                }
                .icon-menu_2 {
                    width: 28px;
                    transform: scaleX(1);
                }
                .icon-menu_3 {
                    width: 28px;
                }
                .icon-mobi__menu.active .icon-menu_1 {
                    transform: rotate(-45deg) translateY(8px);
                    transition: transform 0.3s ease 0s;
                }
                .icon-mobi__menu.active .icon-menu_2 {
                    transform: scaleX(0);
                    transition: transform 0.3s ease 0s;
                }
                .icon-mobi__menu.active .icon-menu_3 {
                    transform: rotate(45deg) translateY(-8px);
                    transition: transform 0.3s ease 0s;
                }
                .navbar-wrapper {
                    flex-wrap: wrap;
                    height: auto;
                    padding: 24px;
                }
                .navbar-wrapper.active .navbar-mobi {
                    display: block;
                    flex-direction: column;
                    order: 3;
                    width: 100%;
                    padding: 24px 0 0 24px;
                }
                    .navbar-pages-wrapper .navbar-pages-page {
                        margin: 0 0 16px 0;
                        font-size: 16px;
                    }
            .navbar-icons-wrapper {
                display: none;
            }
            .navbar-city {
                margin: 0;
            }
            .navbar-city__checked {
                justify-content: end;
            }
            .navbar-city__select {
                right: 0;
                transform: unset;
            }
                .navbar-city__select:after {
                    left: auto;
                    right: 24px;
                    margin: 0;
                }
}